import { gql, useQuery, useLazyQuery } from '@apollo/client';

const PROVINCE_QUERY = gql`
  query getProvinces{
    getProvinces{
      id,
      name
    }
  }
`;

export const getProvinces = () => {
  return useQuery(PROVINCE_QUERY);
};

export const getLazyProvinces = () => {
  return useLazyQuery(PROVINCE_QUERY);
};

const REGENCY_QUERY = gql`
  query getRegencies($provinceId: Int){
    getRegencies( input: {
      provinceId: $provinceId,
    }){
      id
      name
      isPopular
      provinceId
    }
  }
`;

export const lazyGetRegencies = () => {
  return useLazyQuery(REGENCY_QUERY);
}

const DISTRICT_QUERY = gql`
  query getDistrict($regencyId: Int){
    getDistricts( input: {
      regencyId: $regencyId,
    }){
      id
      name
    }
}`;

export const lazyGetDistrict = () => {
  return useLazyQuery(DISTRICT_QUERY);
}

const VILLAGE_QUERY = gql`
  query getVillagesByDistrictId($districtId: Int){
    getVillages( input: {
      districtId: $districtId,
    }){
      id
      name
    }
  }
`;

export const lazyGetVillagesByDistrictId = () => {
  return useLazyQuery(VILLAGE_QUERY);
}

const POSTAL_CODE_QUERY = gql`
  query getPostalCodes($villageIds: [Int!]){
    getPostalCodes( input: {
      limit: 20,
      villageIds: $villageIds
    }){
      id
      postalCode
    }
  }
`;

export const lazyGetPostalCode = () => {
  return useLazyQuery(POSTAL_CODE_QUERY);
}