import React from 'react';
import './InputQuotationLife.scss';
import Cleave from 'cleave.js/react';
import classnames from 'classnames';

const clearNumber = (number) => {
  return number.replace(/,/g, '');
}
class InputQuotationLife extends React.Component {
  render() {

    const { field, errors, setFieldValue, disabled } = this.props;

    return (
      <div className="InputQuotationLife">
        <Cleave
          {...field}
          disabled={disabled}
          onChange={(e) => setFieldValue(field.name, clearNumber(e.target.value))}
          placeholder='Rp'
          options={{
            numeral: true,
            numeralIntegerScale: 9
          }}
          className={classnames('form-control', { 'is-invalid': errors })}
        />
        <i style={{
          cursor: 'pointer',
          display: field.value ? 'block' : 'none'
        }}
          onClick={() => setFieldValue(field.name, '')}
          className={classnames('icon ion-md-close')} />
      </div>
    )
  }
}

export default InputQuotationLife;
