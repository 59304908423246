import React from 'react'
import EditableDropdown from '../../../client/modules/agents/components/EditableDropdown';

export default function ProvinceEditableDropdown(props) {
  const {data: provinces} = getProvinces();

  const provincesOptions = provinces?.getProvinces.map(province => {
    return {
      value: province.name,
      label: province.name
    }
  })

  return (
    <EditableDropdown
      options={provincesOptions || []}
      {...props}
    />
  )
}
