import * as React from 'react';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.id';
import './Input.scss';

const Input = (props) => {
  const [phoneVal, setPhoneVal] = React.useState('');

  const originalProps = Object.assign({}, props);
  delete originalProps.text;
  delete originalProps.currency;
  delete originalProps.password;
  delete originalProps.email;
  delete originalProps.time;
  delete originalProps.number;
  delete originalProps.phone;
  delete originalProps.creditCard;
  delete originalProps.error;
  delete originalProps.disableContextMenu;
  delete originalProps.alphabet;
  delete originalProps.search;
  delete originalProps.textarea;
  delete originalProps.onUnfocus;
  delete originalProps.hideDebitNumber;
  delete originalProps.noDecimal;
  delete originalProps.maskMobileNumber;

  let type;
  if (props.password) {
    type = 'password';
  } else if (props.email) {
    type = 'email';
  } else if (props.number) {
    type = 'number';
  } else if (props.time) {
    type = 'time';
  } else if (props.textarea) {
    type = 'textarea';
  } else {
    type = 'text';
  }

  if (props.disableContextMenu) {
    document.oncontextmenu = (e) => {
      const { target } = e;
      if (target.name === 'bankAccountConfirmation') {
        return false;
      }
    };
  }

  let render = (
    <input
      {...originalProps}
      className={classNames('Wrapper', 'input', props.error && 'color-lp-red')}
      type={type}
      onPaste={(e) => {
        if (props.disableContextMenu) {
          e.preventDefault();
          return false;
        }
      }}
      onBlur={() => {
        if (props.onUnfocus) {
          props.onUnfocus();
        }
      }}
    />
  );

  if (props.currency) {
    render = (
      <div
        className={classNames(
          !props.withoutPrefixBox && 'Currency',
          props.error && 'CurrencyError'
        )}
        data-text={props.currencyPrefix || 'Rp'}
      >
        <Cleave
          {...originalProps}
          options={{
            numeral: true,
            numeralIntegerScale: 12,
            ...(props.withoutPrefixBox && {
              numeralDecimalMark: ',',
              delimiter: '.',
              prefix: props.currencyPrefix || 'Rp ',
              rawValueTrimPrefix: true,
            }),
            ...(props.noDecimal && {
              numeralDecimalScale: 0,
            }),
          }}
          className={classNames(
            'Wrapper',
            'input',
            !props.withoutPrefixBox && 'PhoneInput',
            props.error && 'color-lp-red'
          )}
          onPaste={(e) => {
            if (props.disableContextMenu) {
              e.preventDefault();
              return false;
            }
          }}
          onBlur={() => {
            if (props.onUnfocus) {
              props.onUnfocus();
            }
          }}
          onKeyUp={props.onKeyUp}
        />
      </div>
    );
  }

  if (props.creditCard) {
    render = (
      <Cleave
        options={{
          ...(props.hideDebitNumber
            ? {
                prefix: '************',
                blocks: [12, 4],
                delimiters: [' '],
                noImmediatePrefix: true,
              }
            : {
                delimiters: [' ', ' ', ' '],
                blocks: [4, 4, 4, 4],
                numericOnly: true,
              }),
          rawValueTrimPrefix: true,
        }}
        {...originalProps}
        type='tel'
        className={classNames(
          'Wrapper',
          'input',
          props.error && 'color-lp-red'
        )}
        onPaste={(e) => {
          if (props.disableContextMenu) {
            e.preventDefault();
            return false;
          }
        }}
        onBlur={() => {
          if (props.onUnfocus) {
            props.onUnfocus();
          }
        }}
      />
    );
  }

  if (props.phone) {
    render = (
      <div
        className={classNames('Phone', props.error && 'PhoneError')}
        data-text='+62'
      >
        <Cleave
          options={{
            ...(props.maskMobileNumber
              ? {
                  delimiters: ['xxxxx'],
                  blocks: [7, ''],
                }
              : {
                  delimiter: ' ',
                  blocks: [3, 4, 4, 1],
                  numericOnly: true,
                }),
          }}
          {...originalProps}
          type='tel'
          className={classNames(
            'Wrapper',
            'PhoneInput',
            'input',
            props.error && 'color-lp-red color-lp-input-red'
          )}
          value={props.value || phoneVal}
          onInput={(e) => {
            const zeroPrefix = /^0/g;
            const phone = e.target.value;
            if (zeroPrefix.test(phone)) {
              setPhoneVal(phone.substring(1));
            }
          }}
          onPaste={(e) => {
            if (props.disableContextMenu) {
              e.preventDefault();
              return false;
            }
          }}
          onBlur={() => {
            if (props.onUnfocus) {
              props.onUnfocus();
            }
          }}
        />
      </div>
    );
  }

  if (props.alphabet) {
    render = (
      <div className='Phone' data-text={props.alphabet}>
        <input
          className={classNames(
            'Wrapper',
            'PhoneInput',
            'input',
            props.error && 'color-lp-red'
          )}
          {...originalProps}
          onPaste={(e) => {
            if (props.disableContextMenu) {
              e.preventDefault();
              return false;
            }
          }}
        />
      </div>
    );
  }

  if (props.search) {
    render = (
      <div className='Search'>
        <input
          className={classNames(
            'Wrapper',
            'SearchInput',
            'input',
            props.error && 'color-lp-red'
          )}
          {...originalProps}
          onPaste={(e) => {
            if (props.disableContextMenu) {
              e.preventDefault();
              return false;
            }
          }}
        />
      </div>
    );
  }

  if (props.textarea) {
    render = (
      <textarea
        className={classNames(
          'TextArea',
          'input',
          props.error && 'color-lp-red'
        )}
        {...props}
      />
    );
  }

  if (props.number) {
    render = (
      <Cleave
        options={{
          numericOnly: true,
        }}
        {...originalProps}
        type='tel'
        className={classNames(
          'Wrapper',
          'input',
          props.error && 'color-lp-red'
        )}
      />
    );
  }

  return (
    <>
      <div
        className={classNames(
          'InputForm control',
          props.error && 'has-icons-right'
        )}
      >
        {render}
        {props.error && (
          <p className={classNames('Help color-error-red mb-1')}>{props.error}</p>
        )}
      </div>
    </>
  );
};
export default Input;
