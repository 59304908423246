import React from 'react';

import classNames from 'classnames';

import { Checkbox } from '@lifepal/lifepal-design-system';

import { Counter, Dropdown } from '../../Form';

import { ageList } from '../../../helper';

import './InputQuotation.scss'

class InputQuotation extends React.Component {
  render() {
    const {
      person,
      age,
      handleClick,
      setChildrenCount,
      handleDropdown,
      personIndex,
      ageIndex,
      activePerson,
      others,
      numberOfChildren,
      isAdditionalChildren,
      dataLength,
    } = this.props;

    let isAllowed = false;

    if (!!activePerson) {
      isAllowed = !activePerson.allowedRelations.includes(person.key);
    }

    let ageOptions = ageList.filter(d => d.value > 17);
    if (person && person.relation && person.relation === 'DP - Anak') {
      ageOptions = ageList.filter(d => d.value < 18);
    }

    return (
      <div className={classNames('InputQuotationForm', isAllowed && 'member__disabled')}>
        {
          !isAdditionalChildren && (
            <Checkbox
              onChange={(event) => handleClick(person.key, event, personIndex)}
              checked={person.isActive}
              disabled={isAllowed}
              id={`member__${personIndex}`}
            />
          )
        }

        <label
          className="InputQuotationForm--title"
          htmlFor={`member__${personIndex}`}
        >
          {person.label}
        </label>

        {
          (
            person.isActive &&
            ['boys', 'daughters'].includes(person.key) &&
            !isAdditionalChildren
          ) && (
            <Counter
              min={1}
              max={3}
              onValueChange={(val) => setChildrenCount(person.key, val, personIndex)}
              isDisableIncrease={numberOfChildren > 2}
              value={person.ageList.length}
            />
          )
        }

        {
          (
            person.isActive && !others &&
            !['boys', 'daughters'].includes(person.key) ||
            isAdditionalChildren
          ) && (
            <Dropdown
              callback={(val) => handleDropdown(person.key, val, personIndex, ageIndex)}
              value={person.age || age}
              useRS
              id="birthPlace"
              options={ageOptions}
              placeholder="Pilih Umur"
              zIndex={dataLength}
            />
          )}
      </div>
    )
  }
}

export default InputQuotation;