import React, { useEffect } from 'react';
import { lazyGetDistrict } from '../../../services/address';
import { Dropdown } from '..'

export default function DistrictField(props) {
  const [getDistricts, { data: districts}] = lazyGetDistrict();

  const districtOptions = districts?.getDistricts.map(district => {
    return {
      value: district.id,
      label: district.name
    }
  });

  useEffect(()=>{
    if(!props.regencyId) {
      return;
    }

    getDistricts({
      variables: {
        regencyId: props.regencyId
      }
    });
  }, [props.regencyId])

  return (
    <Dropdown
      useRS
      options={districtOptions || []}
      placeholder="- Pilih atau Cari Kelurahan"
      {...props}
    />
  )
}
