import * as React from 'react';
import './Field.scss';
import classNames from 'classnames';

const FieldComponent = props => (
  <div className={classNames('field', 'FieldForm')} {...props}>
    {props.children}
  </div>
);
export default FieldComponent;
