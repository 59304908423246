export const getQAValidationKeys = (title) => {
  let validationKeys = [];

  switch (true) {
    case ['Saya Sendiri', 'Pemegang Polis'].includes(title):
      validationKeys =
        ['fullName', 'gender', 'birthPlace', 'dob', 'address', 'province', 'city', 'district', 'subDistrict', 'postalCode', 'phoneNumber', 'monthlyIncome', 'sourceOfFund', 'identityCardNumber', 'passportNumber', 'familyCardNumber'];
      break;
    case title === 'Pasangan':
      validationKeys = ['fullName', 'gender', 'birthPlace', 'dob', 'identityCardNumber', 'passportNumber', 'familyCardNumber'];
      break;
    case title.includes('Anak'):
      validationKeys = ['fullName', 'gender', 'birthPlace', 'dob'];
      break;
  }

  return validationKeys;
};
