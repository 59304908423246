export const paymentDetailsLib = {
  paymentMethod: 'Metode Pembayaran',
  bankName: 'Nama Bank',
  bankAccount: 'Nomor Rekening',
  bankHolder: 'Nama Rekening',
  bankDetail: 'Cabang',
  tenure: 'Jangka Tagihan',
};

export const vehicleDetailsLib = {
  area: 'Plat Kendaraan',
  category: 'Kategory Kendaraan',
  brand: 'Merk Mobil',
  model: 'Model Mobil',
  type: 'Tipe Mobil',
  year: 'Tahun Pembuatan mobil',
};

export const formLabel = {
  email: 'Email',
  fullName: 'Nama Lengkap',
  gender: 'Jenis Kelamin',
  citizenship: 'Kewarganegaraan',
  birthPlace: 'Tempat Lahir',
  address: 'Nama Jalan',
  dob: 'Tanggal Lahir',
  rt: 'RT',
  rw: 'RW',
  province: 'Provinsi',
  city: 'Kota',
  district: 'Kecamatan',
  subDistrict: 'Kelurahan',
  postalCode: 'Kode Pos',
  phoneNumber: 'Nomor Telepon',
  licensePlate: 'Plat Kendaraan',
  vehicleColor: 'Warna Kendaraan',
  engineNumber: 'Nomor Kendaraan',
  frameNumber: 'Nomor Rangka',
  relation: 'Hubungan Dengan Tertanggung',
  percentageBeneficiary: 'Persentase Pembagian',
};

export const headerActionLib = {
  CANCEL: {
    message: 'Apakah Anda Ingin Membuat Aplikasi Baru Untuk Pelanggan Ini?',
    leftButton: 'Batal',
    rightButton: 'Hapus aplikasi ini'
  },
  SUSPEND: {
    message: 'Suspend Order Ini?',
    leftButton: 'Batal',
    rightButton: 'Suspend'
  },
  ACTIVE: {
    message: 'Approve Order Ini?',
    leftButton: 'Batal',
    rightButton: 'Approve'
  },
  REVISE: {
    message: 'Revisi Aplikasi Ini?',
    leftButton: 'Batal',
    rightButton: 'Revisi'
  },
  REFUND: {
    message: 'Detail Cancel',
    leftButton: 'Cancel',
  },
};