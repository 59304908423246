import * as React from 'react';
// import { Wrapper, Button, Input } from './Counter.module.scss';
import './Counter.scss';

class Counter extends React.PureComponent {
  state = {
    value: Number(this.props.value) || Number(this.props.min) || 0,
  };

  handleDecrease = () =>
    this.setState(
      ({ value }) => ({
        value:
          this.props.min < Number(value)
            ? Number(value) - 1
            : Number(this.props.min),
      }),
      () => {
        this.props.onValueChange(String(this.state.value));
      }
    )
  handleIncrease = () =>
    this.setState(
      ({ value }) => ({
        value:
          this.props.max > Number(value)
            ? Number(value) + 1
            : Number(this.props.max),
      }),
      () => {
        this.props.onValueChange(String(this.state.value));
      }
    )

  handleChange = (e) => {
    this.setState({ value: Number(e.target.value) });
    this.props.onValueChange(e.target.value);
  }

  render = () => {
    const { value } = this.state;
    const { styles, isDisableIncrease } = this.props;

    return (
      <div className="CounterForm" style={styles}>
        <div className="Wrapper">
          <button type="button" className="Button" onClick={this.handleDecrease}>
            <span className="icon ion-md-remove" />
          </button>
          <input
            disabled
            type="number"
            className="Input"
            placeholder="0"
            value={value}
            onChange={this.handleChange}
          />
          <button type="button" className="Button" disabled={isDisableIncrease} onClick={this.handleIncrease}>
            <span className="icon ion-md-add" />
          </button>
        </div>
      </div>
    );
  }
}

export default Counter;
