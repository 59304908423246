import * as React from 'react';
import classNames from 'classnames';
import './Picker.scss';

const Picker = props => (
  <div className="CheckboxForm">
    <ul className="Wrapper">
      {props.options.map((row, key) => (
        <li
          key={key}
          onClick={() => {
            if (props.onClick) props.onClick(row.value)
          }}
          className={props.className}
        >
          <label>
            <input
              type="radio"
              name={row.name}
              onChange={e => {
                props.onChange(e.target.value)
              }}
              className="Input"
              value={row.value}
              checked={props.value === row.value}
              disabled={props.disabled || row.disabled}
            />
            <div
              className="InputWrapper"
              tabIndex="0"
              onBlur={() => {
                if (props.onUnfocus) {
                  props.onUnfocus();
                }
              }}
            >
              <span
                className={classNames(
                  'icon ion-md-radio-button-on',
                  'Icon',
                  'IconOn'
                )}
              />
              <span
                className={classNames(
                  'icon ion-md-radio-button-off',
                  'Icon',
                  'IconOff'
                )}
              />

              <span className="Text">{row.label}</span>
            </div>
          </label>
        </li>
      ))}
    </ul>
    {props.error && (
      <p className={classNames('help color-lp-red', "Help")}>{props.error}</p>
    )}
  </div>
);
export default Picker;
