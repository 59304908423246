import * as React from 'react';
import classNames from 'classnames';
import './Label.scss';

const Label = props => {
  const originalProps = Object.assign({}, props, {
    info: undefined,
    disabled: undefined,
  });

  return (
    <label {...originalProps} className="label LabelForm">
      <span
        className={classNames(
          'Caption',
          props.disabled && 'Disabled',
          props.info && 'is-pulled-left'
        )}
      >
        {props.children}
      </span>
      {props.info && (
        <div className={classNames('is-pulled-right', 'Info')}>{props.info}</div>
      )}
    </label>
  );
};
export default Label;
