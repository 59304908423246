import React from 'react'
import EditableDropdown from '../../../client/modules/agents/components/EditableDropdown';
import { lazyGetDistrict } from '../../../services/address';

export default function DistrictEditableDropdown(props) {
  const [getDistricts, { data: districts} ] = lazyGetDistrict();

  const districtOptions = districts?.getDistricts.map(district => {
    return {
      value: district.id,
      label: district.name
    }
  })

  useEffect(()=>{
    if(!props.regencyId) {
      return;
    }

    getDistricts({
      variables: {
        regencyId: props.regencyId
      }
    });
  }, [props.regencyId])

  return (
    <EditableDropdown
      options={districtOptions || []}
      {...props}
    />
  )
}
