import * as React from 'react';
import dynamic from 'next/dynamic';
import './Dropdown.scss';
import classNames from 'classnames';

const Select = dynamic(() => import('react-select'), { ssr: false });
const SelectAsync = dynamic(() => import('react-select/async'), { ssr: false });

const Dropdown = props => {
  const originalProps = Object.assign({}, props);
  delete originalProps.options;
  delete originalProps.placeholder;
  delete originalProps.useRS;
  delete originalProps.error;
  delete originalProps.isSearch;
  delete originalProps.hideArrow;
  delete originalProps.zIndex;
  delete originalProps.callback;

  const loadAJAX = (inputValue, callback) => {
    if (props.loadOptions) {
      props.loadOptions(inputValue, callback);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      ...props.styles,
      boxShadow: 'none',
      cursor: 'pointer',
      backgroundColor: '#1d66dd',
      borderStyle: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: (props.styles && props.styles.maxHeight)
        ? props.styles.maxHeight
        : '260px'
    })
  }
  
  return (
    <div className={`DropdownFormTransparent ${props && props.className}`}>
      <div className='Wrapper' style={{ zIndex: props.zIndex }}>
        {!props.useRS ? (
          <>
            <select {...originalProps}>
              <option value="" disabled>
                {props.placeholder ? props.placeholder : 'Placeholder'}
              </option>
              {props.options.map((row, index) => (
                <option key={index} value={row.value}>
                  {row.label}
                </option>
              ))}
            </select>
            {!props.hideArrow && <i className="icon ion-ios-arrow-down"></i>}
          </>
        ) : (
            <div className={classNames('ReactSelect', props.hideArrow && 'HideArrow')}>
              {!props.async && !props.loadOptions ? (
                <>
                  <Select
                    isSearchable={props.isSearch}
                    styles={customStyles}
                    options={props.options}
                    placeholder={props.placeholder}
                    onChange={async (selected) => {
                      if (props.setFieldValue) {
                        props.setFieldValue(props.name, selected.value);
                      }

                      if (props.callback) {
                        await props.callback(selected.value);
                      }

                      if (props.getData) {
                        props.getData(selected.data);
                      }
                    }}
                    onBlur={() => {
                      if (props.onUnfocus) {
                        props.onUnfocus();
                      }
                    }}
                    value={
                      props.value
                        ? {
                          value: props.value,
                          label: props.options.find(
                            row => row.value === props.value
                          )
                            ? props.options.find(row => row.value === props.value)
                              .label
                            : props.value,
                        }
                        : null
                    }
                    isDisabled={props.disabled}
                  />
                </>
              ) : (
                  <SelectAsync
                    isSearchable={props.isSearch}
                    loadOptions={loadAJAX}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        boxShadow: 'none',
                        cursor: 'pointer',
                        borderColor: state.isFocused
                          ? '#1d66dd'
                          : provided.borderColor,
                      }),
                    }}
                    options={props.options}
                    placeholder={props.placeholder}
                    onChange={async (selected) => {
                      if (props.setFieldValue) {
                        props.setFieldValue(props.name, selected.value);
                      }

                      if (props.callback) {
                        await props.callback(selected.value);
                      }
                    }}
                    value={
                      props.value
                        ? {
                          value: props.value,
                          label: props.options.find(row => row.value === props.value)
                            ? props.options.find(row => row.value === props.value)
                              .label
                            : props.value,
                        }
                        : null
                    }
                    isDisabled={props.disabled}
                  />
                )}
            </div>
          )}
        {props.error && (
          <p className={classNames('help color-lp-red', 'Help')}>{props.error}</p>
        )}
      </div>
    </div>
  );
};
export default Dropdown;
