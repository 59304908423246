import React from 'react';

import classNames from 'classnames';

import { formLabel } from '../../../../lib/agentsReview';
import { getQAValidationKeys } from '../../../utils/QAValidationKeys';

const EditorWrapper = ({
  currentState,
  name,
  saveChanges,
  cancelEdit,
  errorMessage,
  edit,
  title,
  children,
  displayValue,
  isSubmitting,
  isEmpty,
  labelType,
}) => {
  return (
    <div className="FieldMargin item-detail">
      <strong>
        <span className={classNames(
          'SpanMargin',
          (isEmpty && getQAValidationKeys(title).includes(name)) && 'SpanMargin--error'
        )}>
          { labelType ? formLabel[`${name}${labelType}`] : formLabel[name]}
        </span>
      </strong>
      {currentState === name
        ? <>
          <span className={classNames('IconBtn SaveBtn SpanRight', isSubmitting && 'disabled')} onClick={saveChanges}>
            Simpan
          </span>
          <span className="IconBtn SaveBtn ErrorMessage" onClick={cancelEdit}>
            Batal
          </span>
          {children}
          {errorMessage && (<p className="ErrorMessage size-small">{errorMessage}</p>)}
        </>
        : <>
          {title !== 'Yang Membayar' && !!edit && (
            <span className="IconBtn lifepal-edit" onClick={() => edit(name)} />
          )}
          {displayValue()}
        </>
      }
    </div>
  )
}

export default EditorWrapper;