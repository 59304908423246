import React from 'react';

import { Dropdown } from '../../../../components/Form';
import EditorWrapper from './EditorWrapper';

const EditableDropdown = ({
  currentState,
  name,
  value,
  saveChanges,
  cancelEdit,
  errorMessage,
  edit,
  title,
  options,
  onChange,
  inputType,
  placeholder,
  origin,
  type,
  percentage,
  isSubmitting,
}) => {
  return (
    <EditorWrapper
      currentState={currentState}
      name={name}
      saveChanges={saveChanges}
      errorMessage={errorMessage}
      edit={edit}
      title={title}
      origin={origin}
      type={type}
      isSubmitting={isSubmitting}
      cancelEdit={() => cancelEdit(origin, name)}
      displayValue={() => (<p>{inputType ? '+62' : ''}{value || '-'}{percentage ? '%' : ''}</p>)}
    >
      <Dropdown
        useRS
        options={options}
        placeholder={placeholder || ''}
        value={value}
        zIndex={6}
        callback={(data) => onChange(data)}
      />
    </EditorWrapper>
  );
}

export default EditableDropdown;