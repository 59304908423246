import { Alert } from 'reactstrap';
import { ApplicationStatus } from './shared/enums';
import { kronosRest } from './client';

const checkLogin = obj => {
  if (obj == null) {
    return false
  }
  return true
}

const errorMessage = (touched, errors, field) =>
  touched[field] &&
  errors[field] && (
    <Alert color="danger" className="mt-3">
      {errors[field]}
    </Alert>
  )

// Show application status
const getStatus = name => {
  const status = {
    SELECT_PLAN: {
      text: ApplicationStatus.SELECT_PLAN,
      color: 'warning'
    },
    POLICY_INFO: {
      text: ApplicationStatus.POLICY_INFO,
      color: 'warning'
    },
    DECLARATION: {
      text: ApplicationStatus.DECLARATION,
      color: 'warning'
    },
    PAYMENT_PENDING: {
      text: ApplicationStatus.PAYMENT_PENDING,
      color: 'warning'
    },
    PAYMENT_PROCESSING: {
      text: ApplicationStatus.PAYMENT_PROCESSING,
      color: 'warning'
    },
    PAYMENT_COMPLETE: {
      text: ApplicationStatus.PAYMENT_COMPLETE,
      color: 'success'
    },
    PAYMENT_FAILED: {
      text: ApplicationStatus.PAYMENT_FAILED,
      color: 'danger'
    },
    APP_CANCELED: {
      text: ApplicationStatus.APP_CANCELED,
      color: 'danger'
    },
    POLICY_SUBMIT: {
      text: ApplicationStatus.POLICY_SUBMIT,
      color: 'success'
    },
    POLICY_ISSUED: {
      text: ApplicationStatus.POLICY_ISSUED,
      color: 'success'
    },
    POLICY_DEACTIVATED: {
      text: ApplicationStatus.POLICY_DEACTIVATED,
      color: 'danger'
    },
    REFUND_COMPLETE: {
      text: ApplicationStatus.REFUND_COMPLETE,
      color: 'success'
    },
    REFUND_FAILED: {
      text: ApplicationStatus.REFUND_FAILED,
      color: 'danger'
    },
    REFUND_PROCESSING: {
      text: ApplicationStatus.REFUND_PROCESSING,
      color: 'warning'
    }
    // PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
    // PAYMENT_FAILED: 'PAYMENT_FAILED',
    // REFUND_PROCESSING: 'REFUND_PROCESSING',
    // REFUND_COMPLETE: 'REFUND_COMPLETE',
    // REFUND_FAILED: 'REFUND_FAILED'
  }

  return (
    <span className={`text-${status[name].color}`}>{status[name].text}</span>
  )
}

/**
 * @function getLogoString get logo string image in assets
 * @param {String} brandName brand name string
 * @param {String} imageExt image extension type string ex: img, png,
 * @returns {String} string path to logo file
 */
const getLogoString = (brandName, imageExt) => {
  return `logo-${brandName.split(' ').join('-')}.${imageExt}`.toLowerCase()
}

/**
 * @function getBrandTermsPdfString get pdf terms string in assets
 * @param {String} brandName brand name string
 * @returns {String} string path to pdf file
 */
const getBrandTermsPdfString = brandName => {
  return `/static/pdf/${brandName.split(' ').join('-')}-terms.pdf`.toLowerCase()
}

/**
 * @function delay delay function to make settimeout as a promise chain
 * @param {Number} time time param in milisecond
 * @param {Any} value any value that you want to put into promise
 * @returns {Any} from value param
 */
const delay = (time, value) => {
  return new Promise(resolve => setTimeout(resolve.bind(null, value), time))
}

//@todo: get rid of this function ASAP
/**
 * @async
 * @function uploadImage upload image
 * @param {Object} fileObject file object
 * @param {String} token token string
 * @returns {Object} fetch data
 */
const uploadImage = (fileObject, token) =>
  new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData()
      formData.append('file', fileObject)

      const result = await fetch(`/api/v1/file/upload`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        body: formData
      })

      if (!result.ok) {
        return reject(result.statusText)
      }

      const fetchData = await result.json()

      return resolve(fetchData)
    } catch (err) {
      return reject(err)
    }
  })

/**
 * @function abbreviateNumber short big number and add abbreviate
 * @param {String} value big number string
 */
const abbreviateNumber = value => {
  const base = Math.floor(Math.log(Math.abs(+value)) / Math.log(1000))
  const suffix = ['', 'jt', 'M', 'T'][base - 1]
  return suffix
    ? String(value / Math.pow(1000, base))
      .replace(/\.0+$/g, '')
      .substring(0, 4)
      .replace(/\.$/, '') +
    ' ' +
    suffix
    : '' + value
}

/**
 * @constant {Array.<string>} dateList an array consisting of date number from 1 to 31
 */
const dateList = [...new Array(31)].map((_, index) => index + 1 + '')

/**
 * @constant {Array.<string>} monthList an array consisting of month number from 1 to 12
 */
const monthList = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember'
]

/**
 * @constant {Array.<string>} yearList a function returning array of year from current year to 70 years ago
 */
const yearList = [...new Array(70)].map(
  (_, index) => new Date().getFullYear() - index + ''
)

/**
 * @constant {Array.<string>} ageList a function returning array of year from current year to 70 years ago
 */
const ageList = [...new Array(80)].map(
  (_, index) => {
    return { label: `${index + 1} Tahun`, value: index + 1 }
  }
)

/**
 * @async
 * @function getVillagesByDistrictId get villages by district id
 * @param {String} districtId district id
 * @return {Array.<Object>} villages array
 */
// TODO: remove LT-3767
const getVillagesByDistrictId = async districtId => {
  return [];
};

/**
 * @typedef {Object} BirthDate
 * @property {number} age
 * @property {number} month
 * @property {number} birthMonth
 * @property {number} date
 */

/**
 * @function calculateAge get age by birth date input
 * @param {Date | String} birthDate user birth date
 * @returns {BirthDate} object of age and month number
 */
const calculateAge = birthDate => {
  const today = new Date()
  const birthDateObject = new Date(birthDate)

  let age = today.getFullYear() - birthDateObject.getFullYear()
  const calculatedMonth = birthDateObject.getMonth() - today.getMonth()
  const birthMonth = birthDateObject.getMonth()
  const todayMonth = today.getMonth()
  if (
    todayMonth < birthMonth ||
    (todayMonth === birthMonth && today.getDate() < birthDateObject.getDate())
  ) {
    age = age - 1
  }

  return {
    age,
    month: calculatedMonth,
    birthMonth,
    date: birthDateObject.getDate()
  }
}

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const titleCase = (phrase = '') => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(phrase => phrase.charAt(0).toUpperCase() + phrase.slice(1))
    .join(' ');
}

const convertDate = (
  dateString,
  monthString,
  yearString
) => {
  return new Date(`${yearString}-${monthString}-${dateString}`);
}

export {
  checkLogin,
  getStatus,
  errorMessage,
  getLogoString,
  getBrandTermsPdfString,
  delay,
  uploadImage,
  abbreviateNumber,
  dateList,
  capitalize,
  titleCase,
  monthList,
  yearList,
  ageList,
  getVillagesByDistrictId,
  calculateAge,
  convertDate
}
