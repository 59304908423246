import React from 'react';
import { getProvinces } from '../../../services/address';
import { Dropdown} from '..'

export default function ProvinceField(props) {
  const {data: provinces} = getProvinces();

  const provincesOptions = provinces?.getProvinces.map(province => {
    return {
      value: province.name,
      label: province.name
    }
  })

  return (
    <Dropdown
      useRS
      options={provincesOptions || []}
      placeholder="- Provinsi"
      {...props}
    />
  )
}
