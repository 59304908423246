import * as React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

const Checkbox = props => (
  <span className="CheckboxForm" style={props.styles}>
    <label className="Wrapper" htmlFor={props.id}>
      <input
        checked={props.checked}
        type="checkbox"
        id={props.id}
        {...Object.assign({}, props, { children: undefined })}
      />
      <span className={classNames('Label', !props.children && 'no-children')}>{props.children}</span>
    </label>
  </span>
);
export default Checkbox;
